import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import { Text } from "../../../components/atoms/Text/Text";
import { isWithinPeriod } from "../../../features/helpers/projectHelper";
import { projectRouterChoice } from "../../../features/helpers/routerHelper";
import { fetchProduct } from "../../../features/productOperation";
import { Project } from "../../../Types/projectType";
import { ProjectPostStatus } from "../../Post/ProjectPost/ProjectPostStatus";
import { ProductListPrice } from "../ProductList/ProductListPrice";

type Props = {
  product: Project;
};

export const Product = (props: Props) => {
  const { width } = useWindowSize();
  const { product } = props;
  const {
    startDiscountPeriod,
    endDiscountPeriod,
    periodDiscountRate,
    salesStatus,
    projectRef,
    image,
  } = product;

  const { sale, underReservation, inProduction, endOfSale } = salesStatus;
  const useStyles = makeStyles({
    productCard: {
      display: "flex",
      flexWrap: "wrap",
    },
    product: {
      paddingTop: 20,
      width: "50%",
      cursor: "pointer",
      paddingBottom: 10,
      "&:hover": {
        backgroundColor: "rgb(230,230,230)",
        transition: "all 0.3s",
      },
    },
    img: {
      width: 230,
      height: 287.5,
      margin: "0 auto",
      display: "block",
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: width * (172.5 / 375),
        height: width * (215.625 / 375),
      },
    },
    colorArea: {
      marginLeft: 10,
      display: "flex",
      justifyContent: "center",
    },
    colorBox: {
      width: 15,
      height: 15,
      border: "1px solid rgba(210, 210, 210, 0.9)",
      margin: "0 5px",
    },
    statusArea: {
      position: "relative",
    },
    productStatusArea: {
      display: "flex",
      gap: 4,
      marginLeft: 10,
      marginTop: 5,
    },
    discountRate: {
      backgroundColor: "#ED1A3D",
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: 0.8,
      height: 20,
    },
    deliveryStatus: {
      backgroundColor: "#64C0AB",
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 20,
      lineHeight: 2.1,
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const isPeriod = isWithinPeriod(
    startDiscountPeriod,
    endDiscountPeriod,
    periodDiscountRate
  );
  return (
    <div
      key={product.projectRef}
      className={classes.product}
      onClick={() => {
        if (underReservation || sale || inProduction || endOfSale) {
          dispatch(fetchProduct(product.projectRef));
        }

        const url = projectRouterChoice(salesStatus, projectRef);

        push(url);
      }}
    >
      <div className={classes.statusArea}>
        {/* {product.salesStatus && <ProjectPostStatus status={product.salesStatus} />} */}
        <img
          src={image ? image : ""}
          alt="productImage"
          className={classes.img}
        />
      </div>
      {salesStatus && (
        <div className={classes.productStatusArea}>
          {isPeriod && (
            <div className={classes.discountRate}>
              <Text
                color="white"
                text={`${periodDiscountRate}% OFF`}
                fontSize={10}
              />
            </div>
          )}
          <ProjectPostStatus status={product.salesStatus} />
          {sale && (
            <div className={classes.deliveryStatus}>
              <Text color="white" text="最短発送" fontSize={10} />
            </div>
          )}
        </div>
      )}
      <Text
        text={product.memer.label}
        isDark
        className={{
          marginTop: 5,
          marginLeft: 10,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      />
      <Text
        text={product.projectName}
        isDark
        className={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      />
      {/* <Text
        text={`${firebaseTimestampToJapaneseDate(product.createdAt)}`}
        isDark
        className={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      />
      <Text
        text={product.productNumber}
        isDark
        className={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 5,
          fontSize: 14,
          "@media screen and (max-width: 499px)": {
            fontSize: 12,
          },
        }}
      /> */}
      <ProductListPrice
        price={product.retailPrice}
        startDiscountPeriod={String(startDiscountPeriod)}
        endDiscountPeriod={String(endDiscountPeriod)}
        periodDiscountRate={periodDiscountRate}
      />
      <div className={classes.colorArea}>
        {product.colors?.map((c, idx) => (
          <div
            key={idx}
            className={classes.colorBox}
            style={
              c.value.includes("linear-gradient")
                ? { backgroundImage: c.value }
                : { backgroundColor: c.value }
            }
          />
        ))}
      </div>
    </div>
  );
};
