import { useCallback, useEffect, useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { GrayBack } from "../MyPage/GrayBack";
import DeliveryStatus from "./DeliveryStatus";
import PurchaseHistory from "./PurchaseHistory";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { fetchPurchaseHistory } from "../../features/deliveryOperation";
import { OrderHistory } from "../../Types/productType";
import { RouteComponentProps } from "react-router-dom";
import { DocumentSnapshot } from "firebase/firestore";
import { Loading } from "../atoms/Loading/Loading";
import { Text } from "../atoms/Text/Text";

export const DeliveryAndPurchaseHistory = (props: RouteComponentProps) => {
  const { history } = props;
  const [purchaseHistoryArray, setPurchaseHistoryArray] = useState<
    OrderHistory[]
  >([]);
  const [lastVisible, setLastVisible] = useState<DocumentSnapshot | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const useStyles = makeStyles({
    historyContainer: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
      overflowX: "hidden",
    },
    emptyHistory: {
      display: "grid",
      height: 400,
      alignContent: "center",
      justifyContent: "center",
    },
    moreButton: {
      width: "100%",
      marginTop: 20,
      height: 40,
      display: hasMore && !isLoading && purchaseHistoryArray ? "flex" : "none",
      justifyContent: "center",
      alignItems: "center",
    },
  });
  const classes = useStyles();

  const fetchMoreHistory = useCallback(async () => {
    setIsLoading(true);
    await fetchPurchaseHistory(
      setPurchaseHistoryArray,
      lastVisible,
      setLastVisible,
      setHasMore,
      setIsLoading
    );
  }, [lastVisible]);

  useEffect(() => {
    fetchMoreHistory();
  }, []);
  return (
    <div className={classes.historyContainer}>
      <BrowserBackHeader label="注文履歴、配送状況確認" browserBack={history} />
      {isLoading && <Loading />}
      {purchaseHistoryArray.length > 0 ? (
        purchaseHistoryArray.map((pha) =>
          !pha.delivered ? (
            <div key={pha.orderNumber}>
              <DeliveryStatus purchaseHistory={pha} />
              <GrayBack height={20} />
            </div>
          ) : (
            <div key={pha.orderNumber}>
              <PurchaseHistory purchaseHistory={pha} />
              <GrayBack height={20} />
            </div>
          )
        )
      ) : (
        <>
          {!isLoading && (
            <div className={classes.emptyHistory}>
              <Typography align="center">現在注文履歴はありません</Typography>
            </div>
          )}
        </>
      )}
      {purchaseHistoryArray.length > 0 && (
        <div className={classes.moreButton} onClick={fetchMoreHistory}>
          <Text
            text="もっと見る"
            isDark
            className={{
              cursor: "pointer",
            }}
            align="center"
          />
        </div>
      )}
    </div>
  );
};
