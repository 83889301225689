import axios, { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import {
  DocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { OrderHistory } from "../Types/productType";
import { auth } from "../firebase";
import { purchaseHistoryCollection } from "../firebasePaths";
import { logError } from "../lib/logError";
import { statusFetchURL } from "./deliveryUtils";
import { firebaseTimestampToDate } from "./helpers/timeHelper";
dayjs.locale(ja);

type Sagawa = {
  okurijoList: {
    okurijoNo: string;
  }[];
  henkDataSyube: string;
};

type SagawaCurrentInfo = {
  status: string;
  message: string;
  date: string;
  salesOffice: string;
  detailMessage?: string;
};

type SagawaDetailInfo = {
  haisBiSyube: string; // 0:未設定 1:配達指定日 2:お届け予定日 3:配達完了日
  haisoYmd: string; // 西暦あり日付
  haisoTm: string; // 時間
  redeliveryDate: string; // 再配達希望日時(「12月17日 午前中」や「12月17日 11時~12時」)
};

type haiyoInfo = {
  haiyoHenkoKahiMessage: string;
  henkData: string;
};

type SagawaType = {
  data: {
    okurijoList: [
      {
        currentInfo: SagawaCurrentInfo;
        haiyoInfo: haiyoInfo;
        detailInfo: SagawaDetailInfo;
        okurijoNo: string;
      }
    ];
  };
};

const headers = new Headers();
headers.set("Content-type", "application/json");

export const fetchDeliveryStatus = async (orderNumber: number) => {
  const ENDPOINT_URL = statusFetchURL;
  const sagawaReqBody: Sagawa = {
    okurijoList: [
      {
        okurijoNo: String(orderNumber), // <- テストデータ、実際はフロントから伝票番号を受ける
      },
    ],
    henkDataSyube: "2",
  };

  const config: AxiosRequestConfig = {
    url: ENDPOINT_URL,
    headers: headers,
    method: "POST",
    data: { postData: sagawaReqBody },
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    logError(error);
  }
};

const deliveryStatuses = {
  // 購入済み
  purchasedStatuses: {
    numbers: ["0001"],
    returnData: {
      status: ["購入済み", "準備中", "配送中", "お客様へお届け済み"],
      step: 0,
    },
  },
  // 準備中
  inPreparationStatuses: {
    numbers: [
      "0011",
      "0021",
      "0023",
      "0024",
      "0025",
      "0026",
      "0027",
      "0071",
      "0072",
      "0079",
    ],
    returnData: {
      status: ["購入済み", "準備中", "配送中", "お客様へお届け済み"],
      step: 1,
    },
  },

  // 配送中
  deliveredStatuses: {
    numbers: ["0031", "0032", "0033", "0073"],
    returnData: {
      status: ["購入済み", "準備中", "配送中", "お客様へお届け済み"],
      step: 2,
    },
  },

  // 運送会社による引取り
  sagawaPickUp: {
    numbers: [
      "0022",
      "0041",
      "0042",
      "0043",
      "0044",
      "0045",
      "0046",
      "0047",
      "0048",
      "0049",
      "004A",
      "004B",
      "004C",
      "004D",
      "0061",
      "0074",
      "0078",
    ],
    returnData: {
      status: [
        "購入済み",
        "準備中",
        "配送中",
        "運送会社による引取り",
        "お客様へお届け済み",
      ],
      step: 3,
    },
  },

  // お客様へお届け済み
  completeStatuses: {
    numbers: ["0051", "0052", "0055", "0077"],
    returnData: {
      status: ["購入済み", "準備中", "配送中", "お客様へお届け済み"],
      step: 3,
    },
  },

  // 指定のコンビニへお届け済み
  storeCompleteStatuses: {
    numbers: ["0054"],
    returnData: {
      status: [
        "購入済み",
        "準備中",
        "配送中",
        "指定のコンビニへお届け済み",
        "お客様へお届け済み",
      ],
      step: 3,
    },
  },

  errorStatus: {
    numbers: [""],
    returnData: {
      status: ["購入済み", "エラー"],
      step: 1,
    },
  },
};

const deliveryStatusTextConvert = (
  sagawaData: SagawaCurrentInfo,
  sagawaHaiyoInfo: haiyoInfo,
  sagawaDetailInfo: SagawaDetailInfo,
  date: Date
) => {
  const now = new Date();

  // 36時間をミリ秒に変換
  const thirtySixHoursInMilliseconds = 36 * 60 * 60 * 1000;

  // 36時間経過しているかどうかを判定
  const isWithin36Hours =
    now.getTime() - date.getTime() <= thirtySixHoursInMilliseconds;
  if (
    deliveryStatuses.purchasedStatuses.numbers.includes(sagawaData.status) ||
    isWithin36Hours
  ) {
    const sagawaStatus = deliveryStatuses.purchasedStatuses.returnData;

    const deliveryStatus = deliveryAddDataMix(
      sagawaStatus,
      sagawaData,
      sagawaHaiyoInfo,
      sagawaDetailInfo
    );

    return deliveryStatus;
  }
  if (
    deliveryStatuses.inPreparationStatuses.numbers.includes(sagawaData.status)
  ) {
    const sagawaStatus = deliveryStatuses.inPreparationStatuses.returnData;

    const deliveryStatus = deliveryAddDataMix(
      sagawaStatus,
      sagawaData,
      sagawaHaiyoInfo,
      sagawaDetailInfo
    );

    return deliveryStatus;
  }
  if (deliveryStatuses.deliveredStatuses.numbers.includes(sagawaData.status)) {
    const sagawaStatus = deliveryStatuses.deliveredStatuses.returnData;

    const deliveryStatus = deliveryAddDataMix(
      sagawaStatus,
      sagawaData,
      sagawaHaiyoInfo,
      sagawaDetailInfo
    );

    return deliveryStatus;
  }
  if (deliveryStatuses.sagawaPickUp.numbers.includes(sagawaData.status)) {
    const sagawaStatus = deliveryStatuses.sagawaPickUp.returnData;

    const deliveryStatus = deliveryAddDataMix(
      sagawaStatus,
      sagawaData,
      sagawaHaiyoInfo,
      sagawaDetailInfo
    );

    return deliveryStatus;
  }
  if (
    deliveryStatuses.storeCompleteStatuses.numbers.includes(sagawaData.status)
  ) {
    const sagawaStatus = deliveryStatuses.storeCompleteStatuses.returnData;

    const deliveryStatus = deliveryAddDataMix(
      sagawaStatus,
      sagawaData,
      sagawaHaiyoInfo,
      sagawaDetailInfo
    );

    return deliveryStatus;
  }
  if (deliveryStatuses.completeStatuses.numbers.includes(sagawaData.status)) {
    const sagawaStatus = deliveryStatuses.completeStatuses.returnData;

    const deliveryStatus = deliveryAddDataMix(
      sagawaStatus,
      sagawaData,
      sagawaHaiyoInfo,
      sagawaDetailInfo
    );

    return deliveryStatus;
  }
  const sagawaStatus = deliveryStatuses.errorStatus.returnData;

  const deliveryStatus = deliveryAddDataMix(
    sagawaStatus,
    sagawaData,
    sagawaHaiyoInfo,
    sagawaDetailInfo
  );

  return deliveryStatus;
};

const deliveryAddDataMix = (
  sagawaStatus: { status: string[]; step: number },
  sagawaData: SagawaCurrentInfo,
  sagawaHaiyoInfo: haiyoInfo,
  sagawaDetailInfo: SagawaDetailInfo
) => {
  const deliveryMessage = sagawaData.detailMessage ?? "";
  const changeDeliveryDateUrl = sagawaHaiyoInfo.henkData;
  const deliveryScheduledDate = deliveryDate(sagawaDetailInfo);
  if (changeDeliveryDateUrl === "") {
    if (sagawaStatus.status.includes("エラー")) {
      const argumentMixData = {
        sagawaStatus,
        deliveryMessage: "只今準備中です、発送までお待ちください",
        // deliveryMessage: "エラーのため、時間を空けてお試しください。", // TODO: 一時的に先行して決済するためコメントアウト、正式はこっちの文言
        deliveryScheduledDate,
      };
      return argumentMixData;
    }
    const argumentMixData = {
      sagawaStatus,
      deliveryMessage,
      deliveryScheduledDate,
    };
    return argumentMixData;
  }
  const argumentMixData = {
    sagawaStatus,
    deliveryMessage,
    deliveryScheduledDate,
    changeDeliveryDateUrl,
  };
  return argumentMixData;
};

// haisBiSyube: string; // 0:未設定 1:配達指定日 2:お届け予定日 3:配達完了日
// haisoYmd: string; // 西暦あり日付
// haisoTm: string; // 時間
// redeliveryDate: string; // 再配達希望日時(「12月17日 午前中」や「12月17日 11時~12時」)

const convertTime = (data: string): string => {
  const hour = data.slice(0, 2);
  const minute = data.slice(2);
  return `${hour}時${minute}分`;
};
const deliveryDate = (sagawaDetailInfo: SagawaDetailInfo) => {
  const { haisBiSyube, haisoTm, haisoYmd, redeliveryDate } = sagawaDetailInfo;
  if (haisBiSyube === "0" || haisBiSyube === "") return "";
  const dateConvert = (
    haisoYmd: string,
    haisoTm: string,
    redeliveryDate: string
  ) => {
    if (redeliveryDate !== "") {
      const deliveryScheduledDate = `${redeliveryDate} 再配達予定`;
      return deliveryScheduledDate;
    }
    const date = dayjs(haisoYmd);
    const month = date.format("MMM");
    const day = date.format("D");
    const time = convertTime(haisoTm);
    if (haisBiSyube === "3") {
      const deliveryScheduledDate = `${month}${day}日　${time} お届け済み`;
      return deliveryScheduledDate;
    }
    const deliveryScheduledDate = `${month}${day}日　${time} お届け予定`;
    return deliveryScheduledDate;
  };

  const res = dateConvert(haisoYmd, haisoTm, redeliveryDate);
  return res;
};

export const addLastDigitOrderNumber = (orderNumber: string) => {
  // 送り状ナンバーを12桁にする関数
  // orderNumber を　７で割ってあまりの数字をorderNumberの末尾に追加
  const number = Number(orderNumber);
  const divisor = 7;
  const reminder = number % divisor;
  const lastDigit = reminder.toString();

  return Number(`${orderNumber}${lastDigit}`);
};

// export const fetchPurchaseHistory = (setState: Function): AppThunk => {
//   return async (): Promise<void> => {
//     const purchaseData: any[] = [];
//     const purchaseDataInDeliveryStatuses: OrderHistory[] = [];
//     let unsubscribe = auth.onAuthStateChanged(async (user) => {
//       if (user) {
//         const uid = user.uid;
//         const purchaseQuery = query(
//           purchaseHistoryCollection(uid),
//           orderBy("createdAt", "desc"),
//           limit(3)
//         );

//         // .orderBy("createdAt", "desc");
//         await getDocs(purchaseQuery).then(async (snapshot) => {
//           snapshot.forEach(async (doc) => {
//             const data = doc.data();
//             purchaseData.push(data);
//           });
//         });

//         await Promise.all(
//           purchaseData.map(async (purchaseData) => {
//             const orderNumberConvert = addLastDigitOrderNumber(
//               purchaseData.orderNumber
//             );
//             const numberRes = (await fetchDeliveryStatus(
//               orderNumberConvert
//             )) as SagawaType;
//             const sagawaData = numberRes.data.okurijoList[0];
//             const status = deliveryStatusTextConvert(
//               sagawaData.currentInfo,
//               sagawaData.haiyoInfo,
//               sagawaData.detailInfo
//             );
//             const deliveryStatusInPurchaseData: OrderHistory = {
//               ...purchaseData,
//               ...status,
//               orderNumber: orderNumberConvert,
//             };

//             purchaseDataInDeliveryStatuses.push(deliveryStatusInPurchaseData);
//           })
//         );

//         setState(purchaseDataInDeliveryStatuses);
//       }
//       unsubscribe();
//     });
//   };
// };

export const fetchPurchaseHistory = async (
  setState: Function,
  lastVisible: DocumentSnapshot | null,
  setLastVisible: Function,
  setHasMore: Function,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const purchaseData: any[] = [];
  const purchaseDataInDeliveryStatuses: OrderHistory[] = [];
  let unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      const uid = user.uid;
      let purchaseQuery = query(
        purchaseHistoryCollection(uid),
        orderBy("createdAt", "desc"),
        where("uid", "==", uid),
        limit(5)
      );

      if (lastVisible) {
        purchaseQuery = query(purchaseQuery, startAfter(lastVisible));
      }

      const snapshot = await getDocs(purchaseQuery);
      snapshot.forEach((doc) => {
        const data = doc.data();
        purchaseData.push(data);
      });

      await Promise.all(
        purchaseData.map(async (purchaseData) => {
          const purchaseDate = firebaseTimestampToDate(purchaseData.createdAt);
          const orderNumberConvert = addLastDigitOrderNumber(
            purchaseData.orderNumber
          );
          const numberRes = (await fetchDeliveryStatus(
            orderNumberConvert
          )) as SagawaType;
          const sagawaData = numberRes.data.okurijoList[0];
          const status = deliveryStatusTextConvert(
            sagawaData.currentInfo,
            sagawaData.haiyoInfo,
            sagawaData.detailInfo,
            purchaseDate
          );
          const deliveryStatusInPurchaseData: OrderHistory = {
            ...purchaseData,
            ...status,
            orderNumber: orderNumberConvert,
          };

          purchaseDataInDeliveryStatuses.push(deliveryStatusInPurchaseData);
        })
      );
      const uniquePurchaseDataInDeliveryStatuses =
        purchaseDataInDeliveryStatuses.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.orderNumber === item.orderNumber)
        );

      setState((prevState: OrderHistory[]) => {
        const newData = uniquePurchaseDataInDeliveryStatuses.filter(
          (newItem) =>
            !prevState.some(
              (prevItem) => prevItem.orderNumber === newItem.orderNumber
            )
        );

        const mergedData = [...prevState, ...newData];

        // createdAtをDate型に変換して降順にソート
        const sortedData = mergedData.sort((a, b) => {
          const dateA = firebaseTimestampToDate(a.createdAt);
          const dateB = firebaseTimestampToDate(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });

        return sortedData;
      });
      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      setLastVisible(lastDoc);
      setHasMore(snapshot.docs.length > 0);
      setIsLoading(false);
    }
    unsubscribe();
  });
};
